/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { amPopin_userConnected$ref } from "./amPopin_userConnected.graphql";
export type amPopinQueryVariables = {||};
export type amPopinQueryResponse = {|
  +$fragmentRefs: amPopin_userConnected$ref
|};
export type amPopinQuery = {|
  variables: amPopinQueryVariables,
  response: amPopinQueryResponse,
|};
*/


/*
query amPopinQuery {
  ...amPopin_userConnected
}

fragment amPopin_userConnected on Query {
  userConnected: viewer {
    crmData {
      corpo {
        hubspot_owner_id
        hubspotOwnerData
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "amPopinQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "amPopin_userConnected"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "amPopinQuery",
    "selections": [
      {
        "alias": "userConnected",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCrmData",
            "kind": "LinkedField",
            "name": "crmData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CorpoCrmData",
                "kind": "LinkedField",
                "name": "corpo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hubspot_owner_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hubspotOwnerData",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "374359f600197f2766b5cf1ebea3cdd7",
    "id": null,
    "metadata": {},
    "name": "amPopinQuery",
    "operationKind": "query",
    "text": "query amPopinQuery {\n  ...amPopin_userConnected\n}\n\nfragment amPopin_userConnected on Query {\n  userConnected: viewer {\n    crmData {\n      corpo {\n        hubspot_owner_id\n        hubspotOwnerData\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0b828e493520d7f46b96030850efb14';

module.exports = node;
