import create from 'zustand';
import getValue from 'get-value';
import stringTemplate from 'string-template';
import MarkdownWithHtml from 'markdown-to-jsx';

const useZusTranslate = create((set) => ({
  lang: 'fr',
  setTradData: (data, lang) => set((state) => ({
    ...state,
    lang: lang || 'fr',
    data,
  })),
}));

const useTranslation = () => ({
  lang: useZusTranslate.getState().lang,
  t: (str, value) => {
    // console.log('valuevaluevalue', value);
    if (!str) return '';
    const newStr = str?.replace?.(':', '.') || '';
    let newTrad = getValue(useZusTranslate.getState().data, newStr);
    if (!newTrad) return str;
    if (typeof newTrad === 'string') {
      newTrad = newTrad?.replaceAll('{$', '{');
      if (value && Object.entries(value).length) {
        newTrad = stringTemplate(newTrad, value);
      }
    }

    return newTrad;
  },
});
function useLang() {
  const { lang: currentLang } = useTranslation();
  return currentLang;
}
// eslint-disable-next-line react/prop-types
function Markdown({ children, ...props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MarkdownWithHtml {...props}>{children || ''}</MarkdownWithHtml>;
}

export {
 Markdown, useLang as lang, useTranslation, useZusTranslate,
};
