/* eslint-disable vars-on-top, no-var,  prefer-rest-params */
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useZusHomeStore } from '@/helpers/context';
import { Header, Footer } from '@/core/dynamic';
import { getScript } from '@/helpers/common';

function WaypointBackground() {
  const [css, setCss] = useState('style2');
  let timer;
  let currentComponentOn = true;
  useEffect(() => {
    currentComponentOn = true;
    return () => {
      currentComponentOn = false;
    };
  }, []);

  useEffect(() => {
    const { setHeaderBackground2 } = useZusHomeStore.getState();
    if (currentComponentOn) {
      setHeaderBackground2(css);
    }
  }, [css]);

  return (
    <InView
      as="div"
      onChange={(inView) => {
        let style = 'style1';
        if (!inView) style = 'style2';
        setCss(style);
      }}
    >
      <span className="absolute invisible">&nbsp;</span>
    </InView>
  );
}

export default function Layout({ animateHeader, children, noFooter }) {
  return (
    <>
      {!animateHeader ? null : <WaypointBackground {...{ animateHeader }} />}
      <Header />
      {children}
      {noFooter || <Footer />}
    </>
  );
}
