import {
  useRef, useEffect, useState,
} from 'react';
import { Transition, TransitionChild } from '@headlessui/react';
import create from 'zustand';
import Icon from '../components/core/icon';

const UseZusModalStore = create((set) => ({
  zusModal1: null,
  setModal1: (modal1) => set((state) => ({ ...state, modal1 })),
  setModal2: (modal2) => set((state) => ({ ...state, modal2 })),
  setModal3: (modal3) => set((state) => ({ ...state, modal3 })),
}));

function ModalWrapper({
  // eslint-disable-next-line react/prop-types
  children, onClose, afterRender, delay, noClose, onIconClose,
}) {
  const contentRef = useRef();
  const [show, setShow] = useState(!delay);

  useEffect(() => {
    let timer;
    function handleClickOutside(event) {
      if (contentRef && !contentRef.current.contains(event.target)) {
        if (onClose) onClose();
      }
    }

    if (delay) {
      if (!show) {
        timer = setTimeout(() => setShow(true), delay);
      }
    }
    if (show) {
      if (!document.getElementsByTagName('body')[0].classList.contains('overflow-hidden')) {
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
      }
      if (!noClose) document.addEventListener('mousedown', handleClickOutside);

      if (afterRender) {
        // console.log('modal afterRender');
        if (delay) setTimeout(afterRender, delay);
        else afterRender();
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Transition show={show}>
      <div className="fixed inset-0 overflow-y-auto z-60">
        <div className="flex items-center justify-center p-0 text-center modal sm:block sm:p-0">
          <div className="inset-0 lg:fixed" aria-hidden="true">
          <TransitionChild
            as="div"
            enter="transition-opacity ease duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            className="absolute inset-0 bg-black-light bg-opacity-70"
          />
          </div>
          {noClose || (
            <div className="fixed z-10 flex items-center justify-center w-12 h-12 p-3 transition duration-300 translate-x-1/2 bg-black rounded-full cursor-pointer lg:hidden group bottom-8 right-1/2" onClick={() => { if (onClose) { onClose(); } }}>
              <Icon
                name="close"
                className="w-4 text-white transition duration-300 fill-current"
                {...(onIconClose ? {
                onClick: () => onIconClose(),
              } : {})}
              />
            </div>
          )}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild
            as="div"
            enter="transition-all duration-300"
            enterFrom="translate-y-32 opacity-0"
            enterTo="translate-y-0 opacity-100"
            className="inline-block text-left align-bottom transition-all transform shadow-2xl h-dvh lg:h-full lg:rounded-2xl sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
          {noClose || (
            <div className="fixed z-10 items-center justify-center hidden w-12 h-12 p-3 transition duration-300 translate-x-1/2 rounded-full cursor-pointer group lg:flex md:p-2 md:h-10 md:w-10 bottom-8 right-1/2 lg:translate-x-0 lg:top-4 lg:right-4 hover:lg:bg-white lg:bg-white" onClick={() => { if (onClose) { onClose(); } }}>
              <Icon
                name="close"
                className="w-4 transition duration-300 fill-current lg:text-dark-light group-hover:text-black"
                {...(onIconClose ? {
                onClick: () => onIconClose(),
              } : {})}
              />
            </div>
          )}
            <div className="h-full">
              <div className="w-screen text-center min-h-dvh lg:min-h-fit lg:h-fit lg:w-full sm:mt-0 sm:text-left" ref={contentRef}>
                {children}
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Transition>
  );
}

function ZusModalProvider() {
  const zusModal1 = UseZusModalStore((state) => state.zusModal1);
  const modal1 = UseZusModalStore((state) => state.modal1);
  const modal2 = UseZusModalStore((state) => state.modal2);
  const modal3 = UseZusModalStore((state) => state.modal3);

  return (
    <>
      <div className={(modal1 ? 'fixed z-60 inset-0 overflow-y-auto' : 'hidden')} id="modal1Container">
        {modal1}
      </div>
      <div className={(modal3 ? 'fixed z-60 inset-0 overflow-y-auto' : 'hidden')}>{modal3}</div>
      <div className={(modal2 ? 'fixed z-60 inset-0 overflow-y-auto' : 'hidden')}>{modal2}</div>
      <div className={(zusModal1 ? '' : 'hidden')}>{zusModal1}</div>
    </>
  );
}

export {
  UseZusModalStore,
  ZusModalProvider,
  ModalWrapper,
  UseZusModalStore as useZusModalStore,
};

export default ZusModalProvider;
