/* eslint-disable react/destructuring-assignment, react-hooks/rules-of-hooks */
import { useQueryLoader, usePreloadedQuery, useClientQuery } from 'react-relay';
import {
 startTransition, Suspense, useEffect, useState, useRef, useImperativeHandle,
} from 'react';
import { nanoid } from 'nanoid';
import ErrorBoundary from './errorBoundary.js';
/*
  This is final component
  populata setData and getVariables
  setData will receive loadquery data get by usePreloadedQuery
  on another "loading component" call through a ref (loadedComponentFwdRef)

  loadQuery call through that "loading component" by ref (loadQueryListenerRef)

*/
function LoadedComponent({
  Component,
  loadQueryListenerFwdRef,
  loadedComponentFwdRef,
  initOnly,
  loadQuery,
  fetchPolicy,
  setFetchPolicy,
  log,
  srcQuery,
  ...props
}) {
  const [isInit, setIsInit] = useState();
  log('loadQueryListenerFwdRef?.current?.getVariables?.()', loadQueryListenerFwdRef?.current?.getVariables?.());
  const data = useClientQuery(
    srcQuery,
    loadQueryListenerFwdRef?.current?.getVariables?.() || {},
  );

  useImperativeHandle(loadedComponentFwdRef, () => ({
    setData: (dataValue) => {
      log('LoadedComponent dataValue', dataValue);
      setIsInit(nanoid());
    },
    getVariables: () => loadQueryListenerFwdRef?.current?.getVariables?.() || {},
  }), [data]);

  log('LoadedComponent log', isInit, data, srcQuery, loadQueryListenerFwdRef?.current?.getVariables?.() || {});

  return (!isInit && !initOnly) || <Component
    {...(props || {})}
    {...{
      data: {
        ...data,
        ...(props?.data || {}),
      },
      loadQuery,
      setFetchPolicy,
    }}
  />;
}

function LoadedHoc({
  queryRef, query, setData, log,
}) {
  const data = usePreloadedQuery(query, queryRef);
  log('LoadedHoc', data, queryRef);
  useEffect(() => {
    log('LoadedHoc UE');
    setData(true);
  }, []);
  return null;
}

function LoadQueryListener({
  loadQueryListenerFwdRef, query, setData, log, variables,
}) {
  const decodeQueryLoaderDataRef = useRef();
  const [queryRef, currentLoadQuery] = useQueryLoader(query);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentVariables, setCurrentVariables] = useState(variables || {});

  useImperativeHandle(loadQueryListenerFwdRef, () => ({
    loadQuery: (variable, fetchPolicy) => {
      setDataLoaded(false);
      log('LoadQueryListener loadQuery', variable, fetchPolicy);
      setCurrentVariables(variable);
      currentLoadQuery(variable, fetchPolicy);
    },
    getVariables: () => currentVariables,
  }), [queryRef, currentVariables, currentLoadQuery, dataLoaded]);

  return (
    <>
      {(!queryRef || dataLoaded) || <LoadedHoc
        key={nanoid()}
        log={log}
        queryRef={queryRef}
        query={query}
        setData={(dataValue) => {
          setData(dataValue);
          setDataLoaded(true);
        }}
      />
      }
    </>
  );
}
/*
  initOnly : dont run at the fist time
*/
const loaderHoc = ({
  Component, query, providerVariables, initOnly, fetchPolicy, log,
}) => function (props) {
  const loadQueryListenerRef = useRef();
  const loadedComponentRef = useRef();
  const [currentfetchPolicy, setFetchPolicy] = useState(fetchPolicy || 'network-only');

  const funcLog = (...args) => {
    if (log) {
      console.log(...args);
    }
  };

  const loadQuery = (loadvariables, loadfetchPolicy) => {
    startTransition(() => {
      loadQueryListenerRef?.current?.loadQuery(loadvariables, loadfetchPolicy || currentfetchPolicy);
    });
  };

  useEffect(() => {
    if (!initOnly) {
      startTransition(() => {
        funcLog('providerVariables?.(props)', providerVariables?.(props), currentfetchPolicy);
        loadQuery(providerVariables?.(props) || {}, { fetchPolicy: currentfetchPolicy });
      });
    }
  }, []);

  funcLog('loaderHocloaderHoc', providerVariables?.(props), initOnly);

  return (
    <>
    <ErrorBoundary fallback={() => {
      useEffect(() => {
        console.log('ErrorBoundary fallback', query);
        loadedComponentRef?.current?.setData('ErrorBoundary fallback');
      }, []);
      return null;
    }}
    >
      <LoadQueryListener
        loadQueryListenerFwdRef={loadQueryListenerRef}
        query={query}
        setData={
          (data) => {
            loadedComponentRef?.current?.setData(data);
          }
        }
        log={funcLog}
        variables={providerVariables?.(props) || {}}
      />
      <LoadedComponent {...{
        ...props,
        Component,
        setFetchPolicy,
        loadQueryListenerFwdRef: loadQueryListenerRef,
        loadedComponentFwdRef: loadedComponentRef,
        initOnly,
        fetchPolicy,
        loadQuery,
        log: funcLog,
        srcQuery: query,
      }}
      />
    </ErrorBoundary>
    </>
  );
};

export default loaderHoc;
