import { useQueryLoader, usePreloadedQuery } from 'react-relay';
import {
  startTransition, Suspense, useEffect, useState, useRef, useImperativeHandle,
} from 'react';
import { nanoid } from 'nanoid';
import miniQL from './miniQLv4';

export default miniQL;

/*
  This is the final component
  populate setData and getVariables
  setData will receive loadQuery data obtained by usePreloadedQuery
  on another "loading component" call through a ref (loadedComponentFwdRef)
  loadQuery call through that "loading component" by ref (loadQueryListenerRef)
*/

function LoadedComponent({
  Component,
  loadedComponentFwdRef,
  initOnly,
  loadQuery,
  fetchPolicy,
  variables,
  setFetchPolicy,
  log,
  ...props
}) {
  const [data, setData] = useState({});
  const [isInit, setIsInit] = useState(false);

  useImperativeHandle(loadedComponentFwdRef, () => ({
    setData: (dataValue) => {
      setData(dataValue);
      setIsInit(true);
    },
    getVariables: () => variables,
  }), [variables]);

  return (!isInit && !initOnly) || (
    <Component
      {...props}
      data={{
        ...data,
        ...props.data,
      }}
      loadQuery={loadQuery}
      setFetchPolicy={setFetchPolicy}
    />
  );
}

function LoadedHoc({
 queryRef, query, setData, log,
}) {
  const data = usePreloadedQuery(query, queryRef);

  useEffect(() => {
    setData(data);
  }, [data, setData]);

  if (log) {
    console.log('LoadedHoc', data);
  }

  return null;
}

function LoadQueryListener({
  loadQueryListenerFwdRef,
  query,
  setData,
  log,
}) {
  const [queryRef, loadQuery] = useQueryLoader(query);
  const [dataLoaded, setDataLoaded] = useState(false);

  useImperativeHandle(loadQueryListenerFwdRef, () => ({
    loadQuery: (variables, fetchPolicy) => {
      setDataLoaded(false);
      loadQuery(variables, { fetchPolicy });
    },
  }), [loadQuery]);

  return (
    <Suspense fallback={<div />}>
      {(!queryRef || dataLoaded) || (
        <LoadedHoc
          key={nanoid()}
          log={log}
          queryRef={queryRef}
          query={query}
          setData={(dataValue) => {
            setData(dataValue);
            setDataLoaded(true);
          }}
        />
      )}
    </Suspense>
  );
}

/*
  initOnly : don't run at the first time
*/
const loaderHoc = ({
  Component, query, providerVariables, initOnly, fetchPolicy = 'network-only', log,
}) => function (props) {
  const loadQueryListenerRef = useRef();
  const LoadedComponentRef = useRef();
  const [currentFetchPolicy, setFetchPolicy] = useState(fetchPolicy);

  const loadQuery = (loadVariables, loadFetchPolicy) => {
    startTransition(() => {
      loadQueryListenerRef.current?.loadQuery(loadVariables, loadFetchPolicy || currentFetchPolicy);
    });
  };

  useEffect(() => {
    if (!initOnly) {
      startTransition(() => {
        if (log) {
          console.log('providerVariables?.(props)', providerVariables?.(props), currentFetchPolicy);
        }
        loadQuery(providerVariables?.(props) || {}, currentFetchPolicy);
      });
    }
  }, [initOnly, providerVariables, props, log, currentFetchPolicy]);

  return (
    <>
      <LoadQueryListener
        loadQueryListenerFwdRef={loadQueryListenerRef}
        query={query}
        setData={(data) => {
          LoadedComponentRef.current?.setData(data);
        }}
        log={log}
      />
      <LoadedComponent
        {...props}
        Component={Component}
        setFetchPolicy={setFetchPolicy}
        variables={providerVariables?.(props) || {}}
        loadedComponentFwdRef={LoadedComponentRef}
        initOnly={initOnly}
        fetchPolicy={fetchPolicy}
        loadQuery={loadQuery}
        log={log}
      />
    </>
  );
};

// export default loaderHoc;
