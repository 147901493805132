import React, { useState, useEffect } from 'react';
import { Transition, Switch } from '@headlessui/react';
import { setCookie, hasCookie, getCookie } from '@/helpers/cookie';
import { useTranslation } from '@/helpers/translate';

const COOKIE_NAME = 'consent';

function CookieConsent() {
  const { t } = useTranslation();
  const [showConsent, setShowConsent] = useState(false);
  const [consent, setConsent] = useState({
    analytics: false,
    marketing: false,
    essential: true,
  });
  const [showSettings, setShowSettings] = useState(false);

  const pushDataLayer = (consentValue) => {
    /* eslint-disable no-undef */
    // console.log(consentValue, 'consentValue');
    if (typeof dataLayer !== 'undefined') {
      let dataLayerValue = '';
      if (['noall', 'noanal', 'noads', 'allow'].includes(consentValue)) {
        dataLayerValue = consentValue;
      } else {
        if (consentValue.analytics && consentValue.marketing) {
          dataLayerValue = 'allow';
        } else if (consentValue.analytics) {
          dataLayerValue = 'noads';
        } else if (consentValue.marketing) {
          dataLayerValue = 'noanal';
        } else {
          dataLayerValue = 'noall';
        }
        setCookie(COOKIE_NAME, dataLayerValue);
      }
      dataLayer.push({
        event: 'cookie_consent_given',
        consentValue: dataLayerValue,
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const checkConsent = () => {
        const consentValue = getCookie(COOKIE_NAME);
        if (!hasCookie(COOKIE_NAME)) {
          setShowConsent(true);
        } else {
          pushDataLayer(consentValue);
        }
      };
      checkConsent();
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  const updateConsentAndPushDataLayer = (value) => {
    setConsent(value);
    pushDataLayer(value);
  };

  const handleConsent = (value) => {
    setShowConsent(false);
    updateConsentAndPushDataLayer(value);
  };

  const handleItemToggle = (item) => {
    setConsent((prevConsent) => {
      const updatedConsent = { ...prevConsent, [item]: !prevConsent[item] };
      return updatedConsent;
    });
  };

  const handleDeclineClick = () => {
    updateConsentAndPushDataLayer(consent);
    setShowConsent(false);
  };

  const handleSettingsClick = () => {
    setShowSettings(true);
  };

  const handleReturnClick = () => {
    setShowSettings(false);
  };

  return (
    <Transition
      show={showConsent}
      enter="transition-all duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {(ref) => (
        <div ref={ref} className="z-20 shadow-button max-w-[280px] lg:max-w-[370px] rounded-lg ml-0 fixed bottom-[85px] left-6 lg:left-4 lg:bottom-4 w-full px-4 py-3 bg-white lg:bg-black bg-opacity-95 flex flex-col items-center justify-center">
          {showSettings ? (
            <div className="flex flex-col w-full">
              <p className="text-base font-medium text-black lg:text-white">{t('core:consent_title')}</p>
              <p className="text-[13px] text-dark lg:text-dark-light">{t('core:consent_p')}</p>
              {Object.entries(consent).map(([key, value]) => (
                <div key={key} className="flex items-center justify-between py-2">
                  <span className="text-sm text-black lg:text-white">{t(`core:consent_${key}`)}</span>
                  <Switch
                    checked={value}
                    onChange={() => handleItemToggle(key)}
                    // eslint-disable-next-line no-nested-ternary
                    className={`${value ? (key === 'essential' ? 'bg-white-dark2 lg:bg-black-light' : 'bg-blue') : 'bg-white-dark4 lg:bg-dark-deep'} relative inline-flex items-center h-7 rounded-full w-12`}
                    disabled={key === 'essential'}
                  >
                    <span className="sr-only">Enable</span>
                    <span className={`${value ? 'translate-x-6' : 'translate-x-1'} inline-block w-5 h-5 transform ${key === 'essential' ? 'bg-white lg:bg-black' : 'bg-white'} rounded-full`} />
                  </Switch>
                </div>
              ))}
              <div className="flex justify-between w-full mt-2">
                <div>
                  <button onClick={handleReturnClick} className="hidden lg:block cursor-pointer    text-sm lg:bg-black lg:border lg:border-dark text-dark lg:text-white-dark3 px-3 py-[8px] lg:py-[6px] rounded-md hover:lg:bg-black-light">{t('core:consent_return')}</button>
                </div>
                <div className="flex gap-x-2">
                  <button onClick={() => handleDeclineClick()} className="cursor-pointer    text-sm lg:bg-black lg:border lg:border-dark text-dark lg:text-white-dark3 px-3 py-[8px] lg:py-[6px] rounded-md hover:lg:bg-black-light">{t('core:consent_denied')}</button>
                  <button onClick={() => handleConsent({ analytics: true, marketing: true })} className="cursor-pointer text-sm font-medium lg:bg-white bg-black hover:bg-white text-white lg:text-black px-3 py-[8px] lg:py-[6px] rounded-md">{t('core:consent_allow_all')}</button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div>
                <p className="text-[13px] text-dark lg:text-white-dark4">{t('core:consent')}</p>
              </div>
              <div className="flex justify-end w-full mt-2 gap-x-2">
                <button onClick={handleSettingsClick} className="cursor-pointer text-sm lg:bg-black lg:border lg:border-dark text-dark lg:text-white-dark3 px-3 py-[8px] lg:py-[6px] rounded-md hover:lg:bg-black-light">{t('core:consent_settings')}</button>
                <button onClick={() => handleConsent({ analytics: true, marketing: true })} className="cursor-pointer font-medium text-sm lg:bg-white bg-black hover:bg-white text-white lg:text-black px-3 py-[8px] lg:py-[6px] rounded-md">{t('core:consent_allow')}</button>
              </div>
            </>
          )}
        </div>
      )}
    </Transition>
  );
}

export default CookieConsent;
