import dynamic from 'next/dynamic';

const Icons = {};

const generateComponentLoaded = (IconComponent) => function IconComponentWrapper(props) {
    return <IconComponent {...props} />;
  };

function NoIcon({ name }) {
  if (process.env.NODE_ENV !== 'development') return null;
  return `[noicon-${name}]`;
}

function loadIcon(name) {
  if (!Icons[name]) {
    Icons[name] = dynamic(
() => import(/* webpackChunkName: 'icons' */ `../icons/${name}.js`)
        .then((module) => {
          const NewIconComponent = module.default;
          return generateComponentLoaded(NewIconComponent);
        })
        .catch(() => NoIcon),
      {
        ssr: false,
        loading: () => <span />,
      },
    );
  }
  return Icons[name];
}

function DynamicIcon({ name, ...props }) {
  const IconComponent = loadIcon(name);
  return <IconComponent name={name} {...props} />;
}

export default DynamicIcon;
