/* eslint-disable no-underscore-dangle */
import create from 'zustand';

const search = {
  initParams: {}, // params from url
  category: null, // could be change by components/search/filters/categories
  filtersOutput: [], // change by components/search/events/filters
  mapOutput: {}, // change by components/search/events/map
  // currentPage: 1, // change by components/search/results/paginator
  resumes: [], // useless
  urls: [], // stock all filters selected => change url
  filterEvent: 'notready', // one event at a time => comefrom all filters
  searchEvent: null, // wait all event , compile data, debounce and run search
  mapEvent: null, // all event bound changed, mapclick => send to search
  pinEvent: null, // pin is clicking => send to search
  spacesData: 'notready', // search event fill this , components/search/results change results
  mapData: {}, // search event fill this, components/map/build => refresh all pin
  mapDataResults: {},
  varGraphql: {}, // search event, compile format graphql data from filterOutput run search
  varPin: {}, // set markerpin opened
  pinData: {}, // for a clusterclick
  pinIdOpen: null, // run search with spaceRefs
  spaceHoverData: {}, // result space hover
  mapHoverData: {}, // map pin hover
  clustersObject: {},
  clearAllMarkers: null,
  userUsingPlateform: null, // user using platform after init
  mapRef: {},
  languageCurrent: '',
  isTour: false,
  datesSelected: {},
  datePicked: [],
  minRiders: null,
  maxRiders: null,
  _initFromApp: false,
};

const useSearchZustandStore = create(() => ({
  ...search,
}));

/* export const SpacePublicProvider = (fnComponent, {
  categoryDefault,
  formulesDefault,
  spaceIdDefault,
  spaceRefDefault,
  spacePublicUrls
}) => {
  console.log('space public init data', categoryDefault, spaceIdDefault)
  useSpacePublicZustandStore.setState({
    category: categoryDefault,
    spaceId: spaceIdDefault,
    spaceRef: spaceRefDefault,
    cartridgeOpenDates: false,
    cartridgeOpenFormules: true,
    spacePublicUrls
  })

  return fnComponent()
}; */

export function initNoClientData(initData) {
  const keyInit = JSON.stringify(initData || {});
  const initFromApp = useSearchZustandStore.getState()?._initFromApp;
  // console.log('initFromApp', initFromApp);
  if (initFromApp === keyInit) return null;
  // console.log('keyInitkeyInit', keyInit);
  useSearchZustandStore.setState({
    ...initData,
    _initFromApp: keyInit,
  });
  return null;
}

export default useSearchZustandStore;
export {
  useSearchZustandStore,
};
