/* eslint-disable camelcase */
import { useEffect, useState } from 'react';

const getRefsValue_Pattern = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

export const isClient = () => typeof window !== 'undefined';

export function getScript(scriptUrl, callback) {
  const script = document.createElement('script');
  script.src = scriptUrl;
  script.onload = callback;

  document.body.appendChild(script);
}

export function isIE() {
  if (!navigator) return false;
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones */
  const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return is_ie;
}

export function getImageOutside(name, ua) {
  const pattern = name.match(/(.*)\.(\w{3,4}$)/);
  if (pattern && ua && !ua.isDesktop) return `${pattern[1]}-m.${pattern[2]}`;
  return name;
}

export function arrayEquals(a, b) {
  return Array.isArray(a)
    && Array.isArray(b)
    && a.length === b.length
    && a.every((val, index) => val === b[index]);
}

export function ucfirst(f) {
  if (!f) return '';
  return f.charAt(0).toUpperCase() + f.slice(1).toLowerCase();
}

export const extWebpJpg = (ua) => {
  if (!ua) return 'webp';
  return ((ua.isSafari && !ua.isChrome) || ua.isIE) ? 'jpg' : 'webp';
};

export const checkIsEmail = (value) => getRefsValue_Pattern.test(value);

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return debouncedValue;
};

export const flatDeep = (arr) => arr.reduce((acc, val) => {
  const newAcc = [...acc];
  newAcc.concat(Array.isArray(val) ? flatDeep(val) : val);
  return newAcc;
}, []);

export const orfetch = {
  simpleCall: ({ url, body = {}, http = process.env.NEXT_PUBLIC_URL_V4 }) => {
    fetch(http + url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }, (err, res) => {
      console.log(err, res);
    });
  },
};

export const debounceFunction = (func, delay) => {
  let timer;
  return function (...args) {
    const self = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};
export const _ = {
  omit: (obj) => Object.fromEntries(
    Object.entries(obj)
      .filter(([key]) => !['blacklisted', 'keys'].includes(key)),
  ),
};

export const round = (value, decimals) => {
  let precision = 2;
  if (decimals >= 0) {
    precision = decimals;
  }
  return +(`${Math.round(`${value || 0}e${precision}`)}e-${precision}`);
};
