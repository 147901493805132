/* eslint-disable react/no-unused-state, react/destructuring-assignment */
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
    if (props?.fallback) {
      this.state.fallback = props.fallback;
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError && this.state.fallback) {
      const FallBack = this.state.fallback;
      return <FallBack />;
    }
    // if (this.state.hasError) return '';
    return this.props.children;
  }
}

export default ErrorBoundary;
