import { parseCookies, setCookie as nookiesSetCookie, destroyCookie } from 'nookies';
import { setCookie, hasCookie, getCookie } from 'cookies-next';

const DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN_V4;

const cookie = {
  get: (name, ctx) => {
    let currentcookie = {};
    if (ctx) currentcookie = parseCookies(ctx);
    else currentcookie = parseCookies();
    return currentcookie[name] || null;
  },
  set: (name, value, options = {}) => {
    nookiesSetCookie(null, name, value, {
      path: '/',
      domain: DOMAIN,
      ...(options.expirationDate ? { expires: new Date(options.expirationDate) } : {}),
      ...(options.maxAge ? { maxAge: options.maxAge } : {}),
    });
  },
  remove: (name) => {
    destroyCookie(null, name, { domain: DOMAIN, path: '/' });
  },
  isConnected: () => {
    const valueCookie1 = cookie.get('webappToken');
    const valueCookie2 = cookie.get('loginState');
    if (typeof window !== 'undefined') {
      const value = window.localStorage.getItem('Meteor.loginToken');
      return !!((value && value !== 'null')
        && (valueCookie1 && valueCookie1 !== 'null')
        && (valueCookie2 && valueCookie2 !== 'null'));
    }
    return false;
  },
  // eslint-disable-next-line max-len
  getTokenConnection: (ctx) => (!cookie.get('webappToken', ctx) ? null : cookie.get('webappToken', ctx)),
  setTokenConnection: ({
    token, expirationDate, meteorHash, user = {}, connectionType,
  }) => {
    if (!token || !expirationDate) return console.log('authen data missing');
    // console.log({ token, expirationDate, meteorHash });
    cookie.set('webappToken', token, { expirationDate });
    cookie.set('loginState', meteorHash, { expirationDate });
    cookie.set('connectionType', connectionType);
    if (typeof localStorage !== 'undefined') {
      // eslint-disable-next-line no-underscore-dangle
      localStorage.setItem('Meteor.userId', user._id);
      localStorage.setItem('Meteor.loginToken', meteorHash);
      localStorage.setItem('Meteor.loginTokenExpires', new Date(expirationDate));
      localStorage.setItem('connectionType', connectionType);
    }
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-underscore-dangle
      window.localStorage.setItem('Meteor.userId', user._id);
      window.localStorage.setItem('Meteor.loginToken', meteorHash);
      window.localStorage.setItem('Meteor.loginTokenExpires', new Date(expirationDate));
      window.localStorage.setItem('connectionType', connectionType);
    }
    return true;
  },
  removeTokenConnection: () => {
    cookie.remove('webappToken');
    cookie.remove('loginState');
    cookie.remove('connectionType');
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('Meteor.userId');
      localStorage.removeItem('Meteor.loginToken');
      localStorage.removeItem('Meteor.loginTokenExpires');
      localStorage.removeItem('connectionType');
    }
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('Meteor.userId');
      window.localStorage.removeItem('Meteor.loginToken');
      window.localStorage.removeItem('Meteor.loginTokenExpires');
      window.localStorage.removeItem('connectionType');
    }
    return true;
  },
  setConnectedUser: () => {
  },
  getConnectedUser: () => {
  },
  // eslint-disable-next-line max-len
  connectionTypeValid: () => ['email', 'RS', 'facebook', 'google', 'linkedin'].includes(cookie.get('connectionType')),
};

export {
  setCookie,
  hasCookie,
  getCookie,
};
export default cookie;
